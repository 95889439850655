import { SystemStyleObject } from '@mui/system';
import { DropdownSelect, DropdownSelectProps } from '@schooly/components/filters';
import { forwardRef } from 'react';

interface CellDropdownSelectProps extends DropdownSelectProps {}

export const CellDropdownSelect = forwardRef<DropdownSelect, CellDropdownSelectProps>(
  ({ sx, ...dropdownProps }, ref) => {
    return (
      <DropdownSelect
        ref={ref}
        sx={(theme) => ({
          height: 46,
          borderRadius: 0,
          margin: theme.spacing('-1px', 0),
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          outlineColor: 'transparent !important',
          ' .right-icon': {
            right: theme.spacing(1),
          },
          '&.dropdownOpened': {
            width: 200,
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.common.light3,
          },
          ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
        })}
        {...dropdownProps}
      />
    );
  },
);
