import {
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LeavingReasonType } from '@schooly/api';
import { LeavingReason } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { ControlTextField } from '@schooly/components/form-text-field';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  CheckIcon,
  CrossIcon,
  DragIcon,
  EmptySchoolSvg,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  ModalSmall,
  PlusIcon,
  RollBackIcon,
  Spin,
} from '@schooly/style';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../components/uikit/Dnd/dnd.styled';
import { useAmplitude } from '../../../hooks/useAmplitude';
import { SchoolGeneralRowAction } from '../SchoolGeneral/SchoolGeneralRowAction';

export interface SchoolLeavingReasonsForm {
  reasons?: LeavingReason[];
}

export interface SchoolLeavingReasonsModalProps {
  opened: boolean;
  loading?: boolean;
  reasons: LeavingReason[];
  onClose?: () => void;
  onSubmit: (data: SchoolLeavingReasonsForm) => void;
}

const getEmptyReason: () => LeavingReason = () => ({
  id: '',
  type: LeavingReasonType.Predefined,
  title: '',
  order: 0,
  archived: false,
  is_used: false,
});

export const LEAVING_REASON_MAX_LENGTH = 250;

export const SchoolLeavingReasonsModal: FC<SchoolLeavingReasonsModalProps> = ({
  opened,
  loading,
  reasons,
  onClose,
  onSubmit,
}) => {
  const { $t, formatMessage } = useIntl();
  const { getConfirmation } = useConfirmationDialog();
  const { trackCreateLeavingReason, trackArchiveLeavingReason } = useAmplitude();

  const [showArchived, setShowArchived] = useState(false);

  const autoSwitchedArchivedOn = useRef(false);

  const form = useForm<SchoolLeavingReasonsForm>({
    defaultValues: {
      reasons: reasons,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    form.setValue('reasons', reasons);
  }, [form, reasons]);

  const { fields, append, remove, replace, move, update } = useFieldArray({
    control: form.control,
    name: 'reasons',
  });

  const [activeReasons, archivedReasons] = useMemo(
    () =>
      fields.reduce<Array<Array<{ index: number; field: typeof fields[number] }>>>(
        (prev, field, index) => {
          prev[field.archived ? 1 : 0].push({ index, field });
          return prev;
        },
        [[], []],
      ),
    [fields],
  );

  const prevActiveFields = usePrevious(activeReasons);

  const firstActiveReason = form.getValues(`reasons.${activeReasons[0]?.index}`);

  const shouldFocusLastItem = Boolean(
    activeReasons.length === 1
      ? !firstActiveReason
      : prevActiveFields && activeReasons && activeReasons.length - prevActiveFields.length === 1,
  );

  const handleAddReason = useCallback(() => {
    append(getEmptyReason());
    trackCreateLeavingReason();
  }, [append, trackCreateLeavingReason]);

  const handleClose = useCallback(async () => {
    if (loading) {
      return;
    }

    if (
      form.formState.isDirty &&
      !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
    ) {
      return;
    }

    onClose?.();
  }, [onClose, loading, getConfirmation, form.formState.isDirty]);

  const handleDeleteReason = useCallback(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([getEmptyReason()]);
      }
    },
    [fields, remove, replace],
  );

  const handleArchiveReasons = useCallback(
    async (index: number) => {
      const field = form.getValues(`reasons.${index}`);

      const isConfirmed = await getConfirmation({
        textId: 'school-leavingNotice-ArchiveConfirmation',
        textValues: { reason: field.title ?? '' },
        confirmTextId: 'yes',
        cancelTextId: 'no',
      });

      if (isConfirmed) {
        update(index, { ...field, archived: true });

        if (!archivedReasons.length && !autoSwitchedArchivedOn.current) {
          setShowArchived(true);
          autoSwitchedArchivedOn.current = true;
        }
      }

      trackArchiveLeavingReason();
    },
    [archivedReasons.length, form, getConfirmation, update, trackArchiveLeavingReason],
  );

  const handleRestoreReasons = useCallback(
    (index: number) => async () => {
      const field = form.getValues(`reasons.${index}`);

      remove(index);
      append({ ...field, archived: false });
    },
    [append, form, remove],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  return (
    <ModalSmall open={opened} onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader active title={formatMessage({ id: 'section-ReasonsForLeaving' })}>
            {archivedReasons.length > 0 && (
              <FormControlLabel
                control={
                  <Switch
                    checked={showArchived}
                    onChange={(event, value) => setShowArchived(value)}
                  />
                }
                label={$t({ id: 'action-ShowArchived' })}
              />
            )}

            <IconButton onClick={handleClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalMain>
            <ModalContent flat active>
              {!fields.length ? (
                <Stack
                  gap={2.5}
                  justifyContent="center"
                  direction="column"
                  alignItems="center"
                  height="100%"
                >
                  <EmptySchoolSvg />

                  <Stack alignItems="center" justifyContent="center" maxWidth={300} gap={2.5}>
                    <Typography variant="h3" textAlign="center">
                      <FormattedMessage id="school-leavingNotice-EmptyReasons" />
                    </Typography>

                    <Button startIcon={<PlusIcon />} onClick={handleAddReason}>
                      <FormattedMessage id="school-leavingNotice-AddReason" />
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="ReasonDroppable">
                    {(provided) => (
                      <DndListWrapper
                        {...provided.droppableProps}
                        sx={{ overflowX: 'hidden' }}
                        ref={provided.innerRef}
                        alignItems="flex-start"
                        gap={2}
                        p={2.5}
                      >
                        {activeReasons?.map(({ field, index }, arrayIndex) => (
                          <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                              <Stack
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                direction="row"
                                gap={1}
                                sx={{
                                  width: '100%',
                                  bgcolor: 'background.paper',
                                }}
                                style={provided.draggableProps.style}
                              >
                                {activeReasons.length > 1 && (
                                  <IconButton
                                    inverse
                                    {...provided.dragHandleProps}
                                    sx={{
                                      mb: form.formState.errors.reasons?.[index] ? 2 : 0,
                                    }}
                                  >
                                    <DragIcon />
                                  </IconButton>
                                )}

                                <ControlTextField
                                  name={`reasons.${index}.title`}
                                  control={form.control}
                                  rules={{
                                    required: true,
                                    validate: (value, formValues) => {
                                      for (let i = 0; i < (formValues.reasons?.length ?? 0); i++) {
                                        if (i === index) {
                                          continue;
                                        }

                                        const field = formValues.reasons![i];

                                        if (
                                          field.title?.trim().toLowerCase() ===
                                          `${value ?? ''}`.trim().toLowerCase()
                                        ) {
                                          return formatMessage({
                                            id: field.archived
                                              ? 'school-leavingNotice-ArchivedExists'
                                              : 'school-leavingNotice-ActiveExists',
                                          });
                                        }
                                      }

                                      if (
                                        value &&
                                        value.length > LEAVING_REASON_MAX_LENGTH &&
                                        !field.is_used
                                      ) {
                                        return formatMessage(
                                          { id: 'input-ErrorMaxLengthValue' },
                                          { value: LEAVING_REASON_MAX_LENGTH },
                                        );
                                      }

                                      return true;
                                    },
                                  }}
                                  label={$t({ id: 'school-leavingNotice-ReasonName' })}
                                  autoFocus={
                                    shouldFocusLastItem && arrayIndex === activeReasons.length - 1
                                  }
                                  disabled={field.is_used}
                                  fullWidth
                                  canClear
                                />

                                {activeReasons.length ? (
                                  <Stack
                                    sx={{
                                      mb: form.formState.errors.reasons?.[index] ? 2 : 0,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <SchoolGeneralRowAction
                                      id={field.is_used ? field.id : undefined}
                                      index={index}
                                      onArchive={handleArchiveReasons}
                                      onDelete={handleDeleteReason}
                                    />
                                  </Stack>
                                ) : null}
                              </Stack>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        <Button variant="text" startIcon={<PlusIcon />} onClick={handleAddReason}>
                          <FormattedMessage id="school-leavingNotice-AddReason" />
                        </Button>

                        {showArchived &&
                          archivedReasons.map(({ field, index }) => (
                            <Stack
                              key={field.id}
                              direction="row"
                              alignItems="center"
                              gap={1}
                              pl={3.5}
                              width="100%"
                            >
                              <TextField
                                value={field.title}
                                fullWidth
                                disabled
                                label={$t({ id: 'school-leavingNotice-ReasonName' })}
                              />

                              <IconButton onClick={handleRestoreReasons(index)}>
                                <RollBackIcon />
                              </IconButton>
                            </Stack>
                          ))}
                      </DndListWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </ModalContent>
          </ModalMain>

          <ModalFooter sx={{ justifyContent: 'space-between' }} active>
            <Button variant="outlined" disabled={loading} onClick={handleClose}>
              <FormattedMessage id="action-Cancel" />
            </Button>

            <Button type="submit" disabled={loading} endIcon={loading ? <Spin /> : <CheckIcon />}>
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
