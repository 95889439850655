import { Button, Icon, Skeleton, Stack, Typography } from '@mui/material';
import {
  AssignedProductWithAvailableVariants,
  ProductBillingType,
  useGetSchoolPaymentFrequencies,
  WithAvatar,
  WithName,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import {
  DropdownSelect,
  DropdownSelectRow,
  SelectContentSkeleton,
} from '@schooly/components/filters';
import {
  ArrowDownV2Icon,
  CellDropdownSelect,
  GridCell,
  GridContainer,
  GridRowName,
  Loading,
  MIN_ROW_HEIGHT,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  Price,
  RowSimple,
  Spin,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, Fragment, ReactNode, useCallback, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import useSchoolYears from '../../../hooks/useSchoolYears';
import { DiscountForm } from '../../../pages/ProfileModal/tabs/ProfileModalAssignedProductsList/SingleInvoices/Products/ProductForms';
import { getDiscountedPrice } from '../../../pages/ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/helpers';
import { AssignedProductUpdate } from '../../../pages/ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/StudentProductsModalContent';
import { getCurrencySymbol } from '../../../pages/School/SchoolProducts/helpers';

type HandleUpdateProductProps = {
  productUpdate: Partial<
    Omit<AssignedProductUpdate, 'variant'> & {
      variant: Partial<AssignedProductWithAvailableVariants['variant']>;
    }
  >;
  index: number;
  groupIndex: number;
};

export type ProductAssignmentsForm = { products: AssignedProductUpdate[][] };

export interface ProductAssignmentsConfirmModalProps<T> {
  onClose: () => void;
  onConfirm: (data: ProductAssignmentsForm) => void;
  products: AssignedProductUpdate[][];
  users: T[];
  saving: boolean;
  title: ReactNode;
  description: ReactNode;
}

export const ProductAssignmentsConfirmModal = <T extends WithName & WithAvatar>({
  onClose,
  onConfirm,
  products,
  users,
  saving,
  title,
  description,
}: ProductAssignmentsConfirmModalProps<T>) => {
  const { schoolId = '' } = useAuth();
  const { defaultValidity, isLoading: isYearsFetching } = useSchoolYears();

  const form = useForm<ProductAssignmentsForm>({ defaultValues: { products } });
  const productsData = form.watch('products');

  const handleUpdateProduct = useCallback(
    ({ productUpdate, groupIndex, index }: HandleUpdateProductProps) => {
      const products = form.getValues('products');
      const currentProducts = productsData[groupIndex];

      if (!currentProducts[index]) return;

      const updatedVariant = { ...currentProducts[index].variant, ...productUpdate.variant };
      const updatedProduct = {
        ...currentProducts[index],
        ...productUpdate,
        variant: updatedVariant,
      };

      const updatedProducts = [...products];
      updatedProducts[groupIndex] = [...currentProducts];
      updatedProducts[groupIndex][index] = updatedProduct;

      form.setValue('products', updatedProducts);
    },
    [form, productsData],
  );

  return (
    <ModalConfirm open onClose={onClose} fullWidth>
      {isYearsFetching ? (
        <Stack sx={{ minHeight: 350, justifyContent: 'center' }}>
          <Loading />
        </Stack>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onConfirm)}>
            <ModalHeader multiline title={title} withBorderBottom={false} active />
            <ModalMain>
              <ModalContent active sx={{ py: 0 }}>
                {description}

                {users.map((user, index) => {
                  const name = getUserFullName(user);
                  if (!products[index].length) {
                    return null;
                  }

                  return (
                    <Fragment key={name}>
                      <Stack direction="row" gap={1} sx={{ alignItems: 'center', my: 2 }}>
                        <AvatarAuth user={user} withAvatarPreview />
                        <TypographyWithOverflowHint variant="h2">
                          {getUserFullName(user)}
                        </TypographyWithOverflowHint>
                      </Stack>

                      <GridContainer
                        sx={(theme) => ({
                          '&:not(:last-of-type)': { mb: 2.5 },
                          border: theme.mixins.borderValue(),
                          backgroundColor: 'transparent',
                          overflowX: 'visible',
                        })}
                      >
                        {productsData[index].map((product, productIndex) => {
                          return (
                            <ProductAssignmentConfirmRow
                              key={product.id}
                              yearId={defaultValidity?.id ?? ''}
                              schoolId={schoolId}
                              product={product}
                              onUpdate={(productUpdate) => {
                                handleUpdateProduct({
                                  productUpdate,
                                  groupIndex: index,
                                  index: productIndex,
                                });
                              }}
                              path={`products.${index}.${productIndex}`}
                            />
                          );
                        })}
                      </GridContainer>
                    </Fragment>
                  );
                })}
              </ModalContent>
            </ModalMain>
            <ModalFooter withBorderTop={false} active sx={{ py: 2.5, mt: 0 }}>
              <Stack gap={2} direction="row" flexGrow={1}>
                <Button fullWidth variant="outlined" onClick={onClose} disabled={saving}>
                  <FormattedMessage id="action-Cancel" />
                </Button>
                <Button
                  fullWidth
                  color="primary"
                  type="submit"
                  disabled={saving}
                  endIcon={saving ? <Spin /> : undefined}
                >
                  <FormattedMessage id="action-Confirm" />
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </FormProvider>
      )}
    </ModalConfirm>
  );
};

type RenderSelectContentProps = {
  isLoading?: boolean;
  options: { id: string; name: string }[];
  onSelect: (id: string) => void;
  selectedId: string;
};

type ProductAssignmentConfirmRowProps = {
  product: AssignedProductUpdate;
  schoolId: string;
  yearId: string;
  onUpdate: (productUpdate: HandleUpdateProductProps['productUpdate']) => void;
  path: string;
};

const ProductAssignmentConfirmRow: FC<ProductAssignmentConfirmRowProps> = ({
  product,
  schoolId,
  yearId,
  onUpdate,
  path,
}) => {
  const { formatMessage } = useIntl();

  const { ref, height } = useResizeDetector();
  const dropdownTypeSelect = useRef<DropdownSelect | null>(null);
  const dropdownPriceSelect = useRef<DropdownSelect | null>(null);

  const rowHeight = height ?? MIN_ROW_HEIGHT;
  const isRecurring = product.product_type === ProductBillingType.Recurring;

  const { price, variant } = useMemo(() => {
    const variant = product.available_variants.find((v) => v.id === product.variant.id);
    const price = variant?.prices.find(
      (p) => p.frequency_id === product.variant.frequency_id,
    )?.price;

    return {
      price,
      variant,
    };
  }, [product.available_variants, product.variant.id, product.variant.frequency_id]);

  const showType = isRecurring && !!product.variant.type_name;
  const showFrequency = isRecurring && variant;

  const priceSelectDisabled = (variant?.prices.length ?? 0) < 2;
  const typeSelectDisabled = product.available_variants.length < 2;

  const { data: frequenciesData } = useGetSchoolPaymentFrequencies({
    school_id: schoolId,
    year_id: yearId,
  });

  const priceOptions =
    variant?.prices.map((p) => ({
      id: p.frequency_id,
      name: formatMessage({
        id: `frequencies-${
          frequenciesData?.frequencies.find((f) => f.id === p.frequency_id)?.type
        }`,
      }),
    })) ?? [];
  const typeOptions = product.available_variants.map(({ type_id, type_name }) => ({
    id: type_id,
    name: type_name,
  }));

  const { handleProductPriceChange, handleTypeChange } = useMemo(
    () => ({
      handleProductPriceChange: (id: string) => {
        onUpdate({ variant: { frequency_id: id } });
      },
      handleTypeChange: (id: string) => {
        const variant = product.available_variants.find((v) => v.type_id === id);
        if (!variant) return;
        const getFrequencyId = () => {
          const hasSameFrequency = variant.prices.some(
            (p) => p.frequency_id === product.variant.frequency_id,
          );
          if (hasSameFrequency) return product.variant.frequency_id;

          return variant.prices[0].frequency_id;
        };

        onUpdate({
          variant: {
            id: variant.id,
            type_name: variant.type_name,
            frequency_id: getFrequencyId(),
          },
        });
      },
    }),
    [onUpdate, product.available_variants, product.variant.frequency_id],
  );

  const renderSelectContent = useCallback(
    ({ isLoading, options, onSelect, selectedId }: RenderSelectContentProps) => {
      if (isLoading) return <SelectContentSkeleton />;

      return (
        <>
          {options.map((o) => (
            <DropdownSelectRow
              key={o.id}
              isSelected={selectedId === o.id}
              onClick={() => onSelect(o.id)}
            >
              {o.name}
            </DropdownSelectRow>
          ))}
        </>
      );
    },
    [],
  );

  const renderRightIcon = useCallback((disabled: boolean) => {
    return disabled ? (
      <></>
    ) : (
      <Icon
        sx={(theme) => ({
          width: theme.spacing(1),
          transform: 'rotate(180deg)',
          path: { stroke: theme.palette.common.grey },
        })}
      >
        <ArrowDownV2Icon />
      </Icon>
    );
  }, []);

  const renderSelectedItem = useCallback((name: string, isLoading?: boolean) => {
    if (isLoading) return <Skeleton />;

    return (
      <Typography color="text.primary" noWrap>
        {name}
      </Typography>
    );
  }, []);

  return (
    <RowSimple
      ref={ref}
      sx={{
        '& .GridRowItem': {
          padding: 0,
          '& .MuiTableCell-root': {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <GridRowName sx={{ flex: '1 1 110px', px: 1, py: 1.25, textWrap: 'wrap' }} width={110}>
        <Typography color="text.primary" variant="h3">
          {product.name}
        </Typography>
      </GridRowName>

      {showType && (
        <GridCell noVerticalPadding width={110}>
          <CellDropdownSelect
            ref={dropdownTypeSelect}
            maxExpandedContainerWidth={200}
            disabled={typeSelectDisabled}
            renderContent={() =>
              renderSelectContent({
                options: typeOptions,
                onSelect: (id) => {
                  handleTypeChange(id);
                  dropdownTypeSelect.current?.close();
                },
                selectedId: product.variant.frequency_id,
              })
            }
            renderRightIcon={() => renderRightIcon(typeSelectDisabled)}
            hasValues
            popperZIndex={(theme) => theme.zIndex.tooltip}
            layoutStyleProps={{ paddingLeft: 1, paddingRight: 3, height: '100%' }}
            sx={(theme) => ({
              height: `${rowHeight + 2}px`,
              transition: 'all .2s, height 0s',
              ' .right-icon': {
                right: theme.spacing(1),
                top: `calc(${rowHeight / 2}px - ${theme.spacing(1)})`,
              },
            })}
          >
            {() => renderSelectedItem(product.variant.type_name)}
          </CellDropdownSelect>
        </GridCell>
      )}

      {showFrequency && (
        <GridCell noVerticalPadding width={110}>
          <CellDropdownSelect
            ref={dropdownPriceSelect}
            maxExpandedContainerWidth={200}
            disabled={priceSelectDisabled}
            renderContent={() =>
              renderSelectContent({
                isLoading: !frequenciesData,
                options: priceOptions,
                onSelect: (id) => {
                  handleProductPriceChange(id);
                  dropdownPriceSelect.current?.close();
                },
                selectedId: product.variant.frequency_id,
              })
            }
            renderRightIcon={() => renderRightIcon(priceSelectDisabled)}
            hasValues
            popperZIndex={(theme) => theme.zIndex.tooltip}
            layoutStyleProps={{ paddingLeft: 1, paddingRight: 3, height: '100%' }}
            sx={(theme) => ({
              height: `${rowHeight + 2}px`,
              transition: 'all .2s, height 0s',
              ' .right-icon': {
                right: theme.spacing(1),
                top: `calc(${rowHeight / 2}px - ${theme.spacing(1)})`,
              },
            })}
          >
            {() =>
              renderSelectedItem(
                formatMessage({
                  id: `frequencies-${
                    frequenciesData?.frequencies.find((f) => f.id === product.variant.frequency_id)
                      ?.type ?? ''
                  }`,
                }),
                !frequenciesData,
              )
            }
          </CellDropdownSelect>
        </GridCell>
      )}

      <GridCell noVerticalPadding width={50}>
        <DiscountForm name={`${path}.discount_percent`} />
      </GridCell>

      {price && (
        <GridCell noVerticalPadding width={130}>
          <Price
            color="common.grey2"
            variant="body1"
            price={
              product.discount_percent ? getDiscountedPrice(price, product.discount_percent) : price
            }
            currency={variant?.currency ? getCurrencySymbol(variant.currency) : undefined}
          />
        </GridCell>
      )}
    </RowSimple>
  );
};
