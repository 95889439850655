import { Box, Chip, Icon, Skeleton, Stack, TableRow, Tooltip, Typography } from '@mui/material';
import {
  DATE_FORMAT_DAY,
  DATE_FORMAT_MONTH_YEAR,
  DATE_FORMAT_RANGE_FNS,
  DependantStudent,
  LineItemCommon,
  PayableFee,
  PayerType,
  ProductBillingType,
} from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import {
  Attention2Icon,
  CompanyIcon,
  Grid,
  GridBody,
  GridCell,
  GridContainer,
  NewTabIcon,
  Price,
  PRICE_SUBTEXT_CLASS_NAME,
  ProfileIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';
import { AssignedProductPayer } from '../../../pages/ProfileModal/tabs/ProfileModalAssignedProductsList/AssignedProductPayer';
import { getCurrencySymbol } from '../../../pages/School/SchoolProducts/helpers';
import {
  DiscrepancyTooltip,
  InvoiceDiscrepancyTooltipTitle,
  LineItemDiscrepancyTooltipTitle,
} from './DiscrepancyTooltip';
import { payableFeeStatusColor, useInvoiceDiscrepancyRecords } from './helpers';
import { PayableFeesRelationType } from './PayableFees';

export type PayableFeeTableProps = {
  payableFee: PayableFee;
  relationType: PayableFeesRelationType;
  isMultipleItemsView: boolean;
  schoolId: string;
  contextMenu?: ReactNode;
  hasInvoice: boolean;
};

export const PayableFeeTable: FC<PayableFeeTableProps> = ({
  payableFee,
  relationType,
  isMultipleItemsView,
  schoolId,
  contextMenu,
  hasInvoice,
}) => {
  const { students, items, currency } = payableFee;
  const { formatMessage } = useIntl();
  const currencySymbol = getCurrencySymbol(currency);
  const { getItemDiscrepancyLabels, invoiceDiscrepancyLabelIds, discrepantItemIds } =
    useInvoiceDiscrepancyRecords(hasInvoice ? payableFee.discrepancy_records : []);

  const hasProcessingIssue = items.some(
    ({ has_billing_events_processing_issue }) => has_billing_events_processing_issue,
  );

  const itemsToRender =
    hasInvoice && payableFee.discrepancy_records.length
      ? payableFee.items.filter((item) => !item.is_deleted || discrepantItemIds.includes(item.id))
      : payableFee.items;
  return (
    <GridContainer>
      <Grid>
        <PayableFeeHeader
          hasProcessingIssue={hasProcessingIssue}
          payableFee={payableFee}
          hasInvoice={hasInvoice}
          relationType={relationType}
          isMultipleItemsView={isMultipleItemsView}
          schoolId={schoolId}
          contextMenu={contextMenu}
          invoiceDiscrepancyLabelIds={invoiceDiscrepancyLabelIds}
        />
        <GridBody>
          {itemsToRender.map((item, i) => {
            const isLastItemRow = i === itemsToRender.length - 1;
            const key = `${item.id}-${item.label}`;
            const student = students.find((s) => item.student_relation_id === s.id);
            const isDeleted = 'is_deleted' in item && item.is_deleted;
            const isVoided = payableFee.status === 'voided';
            const discrepancyLabels = getItemDiscrepancyLabels(item.id);
            const showDiscrepancyIcon =
              (!!discrepancyLabels.length && !hasProcessingIssue) ||
              item.has_billing_events_processing_issue;

            return (
              <LineItemRow
                isDeleted={isDeleted || isVoided}
                key={key}
                item={item}
                student={student}
                currency={currencySymbol}
                isLastItemRow={isLastItemRow}
                isMultipleItemsView={isMultipleItemsView}
                discrepancyRecord={
                  showDiscrepancyIcon
                    ? showDiscrepancyIcon && (
                        <Stack alignItems="flex-end">
                          <DiscrepancyTooltip
                            title={
                              hasProcessingIssue ? (
                                formatMessage({
                                  id: 'payableFees-Discrepancy-IssueProcessingChanges',
                                })
                              ) : (
                                <LineItemDiscrepancyTooltipTitle
                                  labels={discrepancyLabels}
                                  invoiceLink={payableFee.invoice_link_for_issuer ?? undefined}
                                />
                              )
                            }
                          >
                            <Icon
                              sx={{
                                color: 'background.paper',
                                path: {
                                  color: hasProcessingIssue ? 'error.main' : 'common.orange',
                                },
                              }}
                            >
                              <Attention2Icon />
                            </Icon>
                          </DiscrepancyTooltip>
                        </Stack>
                      )
                    : undefined
                }
              />
            );
          })}
        </GridBody>
      </Grid>
    </GridContainer>
  );
};

type PayableFeeHeaderProps = {
  payableFee: Omit<PayableFee, 'items'>;
  hasProcessingIssue: boolean;
  hasInvoice: boolean;
  relationType: PayableFeesRelationType;
  schoolId: string;
  isMultipleItemsView: boolean;
  contextMenu?: ReactNode;
  invoiceDiscrepancyLabelIds?: string[];
};

export const PayableFeeHeader: FC<PayableFeeHeaderProps> = ({
  hasProcessingIssue,
  payableFee,
  relationType,
  schoolId,
  isMultipleItemsView,
  contextMenu,
  invoiceDiscrepancyLabelIds,
}) => {
  const { formatMessage } = useIntl();
  const {
    payer,
    issue_date,
    invoice_date,
    due_date,
    total_payment,
    status,
    total_paid,
    due,
    currency,
  } = payableFee;
  const navigate = useNavigate();
  const currencySymbol = getCurrencySymbol(currency);
  const showDiscrepancyIcon = !!invoiceDiscrepancyLabelIds?.length || hasProcessingIssue;

  const isCompanyPayer = payer.type === PayerType.Company;
  const payerName = isCompanyPayer ? payer.data.name : getUserFullName(payer.data);
  const payerIcon = isCompanyPayer ? <CompanyIcon /> : <ProfileIcon />;
  const contactName = isCompanyPayer ? payer.data.contact_name : undefined;
  const inviteStatus = isCompanyPayer ? undefined : payer.data.invite_status;
  const payerId = isCompanyPayer ? payer.data.id : payer.data.relation_id;

  const isCompany = relationType === 'company';
  const isStudent = relationType === 'student';
  const payerPath = isCompanyPayer
    ? `/companies/${payer.data.id}`
    : `/parents/${payer.data.relation_id}`;

  const companyStudent = payableFee.students[0];
  const generationDate = newDateTimezoneOffset(issue_date);
  const generationDay = format(generationDate, DATE_FORMAT_DAY);
  const generationMonthYear = format(generationDate, DATE_FORMAT_MONTH_YEAR);
  const invoiceDate = format(newDateTimezoneOffset(invoice_date), DATE_FORMAT_RANGE_FNS);

  const statusColor = payableFeeStatusColor[status];
  const isVoided = status === 'voided';

  const renderFeeStatus = () => {
    const renderLabelWithPrice = (v: number | null, color: string, label: string) => {
      if (!v) return null;

      return (
        <Stack minWidth={90}>
          <Typography sx={{ color: `${color} !important` }}>{label}</Typography>
          <TypographyWithOverflowHint>
            <Price variant="body1" currency={currencySymbol} price={v} />
          </TypographyWithOverflowHint>
        </Stack>
      );
    };

    const label = formatMessage({ id: `payableFees-status-${payableFee.status}` });

    const renderLabel = () => (
      <Stack>
        <Typography sx={{ color: `${statusColor} !important` }}>{label}</Typography>
      </Stack>
    );

    switch (payableFee.status) {
      case 'cancelled':
      case 'unpaid':
      case 'voided':
      case 'upcoming':
      case 'projected':
      case 'paid':
        return renderLabel();
      case 'partially_paid':
        const isOverdue = newDateTimezoneOffset(payableFee.due_date) < new Date();

        return (
          <>
            {renderLabelWithPrice(
              total_paid,
              payableFeeStatusColor['paid'],
              formatMessage({ id: `payableFees-status-paid` }),
            )}
            {renderLabelWithPrice(
              due,
              isOverdue ? payableFeeStatusColor['overdue'] : statusColor,
              isOverdue ? formatMessage({ id: `payableFees-status-overdue` }) : label,
            )}
          </>
        );
      case 'due':
      case 'overdue':
        if (!total_paid) return renderLabel();

        return (
          <>
            {renderLabelWithPrice(
              total_paid,
              payableFeeStatusColor['paid'],
              formatMessage({ id: `payableFees-status-paid` }),
            )}
            {renderLabelWithPrice(due, statusColor, label)}
          </>
        );
    }
  };

  return (
    <TableRow
      sx={(theme) => ({
        height: '40px !important',
        '.MuiTypography-root:not(.invoiceDate)': {
          color: isVoided ? theme.palette.text.secondary : undefined,
        },
        '&& .MuiTableCell-root': {
          py: 1.5,
        },

        '&:hover': {
          '.MuiTypography-root, .linkIcon': {
            color: isVoided ? theme.palette.text.primary : theme.palette.primary.main,
          },
          '.invoiceDate': {
            color: theme.palette.primary.main,
          },
          'td.MuiTableCell-root': {
            backgroundColor: theme.palette.background.default,
          },
          [` .${PRICE_SUBTEXT_CLASS_NAME} , .invoiceDateLabel`]: {
            color: theme.palette.text.primary,
          },
          ' .MuiChip-root': {
            bgcolor: theme.palette.background.paper,
            color: theme.palette.primary.main,
          },
        },
      })}
    >
      <GridCell borderBottom width={240}>
        <Stack direction="row" gap={2} alignItems="center">
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  padding: theme.spacing(0, 0.5),
                  borderRadius: theme.spacing(0.5),
                }),
              },
            }}
            title={formatMessage({ id: 'frequencies-GenerationDate' })}
          >
            <Stack
              sx={(theme) => ({
                alignItems: 'center',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.spacing(1),
                width: theme.spacing(5.75),
                height: theme.spacing(5.75),
                '.MuiTypography-root': {
                  color: 'common.grey2',
                },
                cursor: 'pointer',
              })}
            >
              <Typography mt={0.25} variant="h2">
                {generationDay}
              </Typography>
              <Typography variant="caption">{generationMonthYear}</Typography>
            </Stack>
          </Tooltip>
          <Box>
            <Typography pr={2} color="common.grey" className="invoiceDateLabel">
              {formatMessage({ id: 'payableFees-InvoiceOn' })}
            </Typography>
            <Typography color="common.grey2" noWrap className="invoiceDate">
              {invoiceDate}
            </Typography>
          </Box>
          <Box>
            <Typography color="common.grey" className="invoiceDateLabel">
              {formatMessage({ id: 'payableFees-DueOn' })}
            </Typography>
            <Typography color="common.grey2" noWrap className="invoiceDate">
              {format(newDateTimezoneOffset(due_date), DATE_FORMAT_RANGE_FNS)}
            </Typography>
          </Box>
        </Stack>
      </GridCell>

      {isStudent && (
        <GridCell borderBottom width={48}>
          <AssignedProductPayer
            name={payerName}
            icon={payerIcon}
            telephone={payer.data.telephone}
            email={payer.data.email}
            id={payerId}
            contactName={contactName}
            inviteStatus={inviteStatus}
            schoolId={schoolId}
            onClick={() => navigate(payerPath)}
            sx={{ cursor: 'pointer' }}
          />
        </GridCell>
      )}

      {isCompany && companyStudent && (
        <GridCell borderBottom width={48}>
          <StudentAvatar student={companyStudent} />
        </GridCell>
      )}
      <GridCell
        borderBottom
        width={isMultipleItemsView ? 220 : 196}
        sx={{ maxWidth: isMultipleItemsView ? 220 : 196 }}
      >
        <Stack direction="row">
          {payableFee.invoice_link_for_issuer && (
            <Link
              to={payableFee.invoice_link_for_issuer}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {payableFee.invoice_number ? (
                <TypographyWithOverflowHint
                  sx={{
                    color: 'common.grey2',
                    textDecoration: isVoided ? 'line-through' : undefined,
                    '&:hover': { textDecoration: isVoided ? undefined : 'underline' },
                  }}
                  variant="h3"
                >
                  {payableFee.invoice_number}
                </TypographyWithOverflowHint>
              ) : (
                <Icon
                  className="linkIcon"
                  fontSize="small"
                  sx={{ color: isVoided ? 'common.grey' : 'common.grey2' }}
                >
                  <NewTabIcon />
                </Icon>
              )}
            </Link>
          )}
          {showDiscrepancyIcon && (
            <DiscrepancyTooltip
              title={
                hasProcessingIssue ? (
                  formatMessage({ id: 'payableFees-Discrepancy-IssueProcessingChanges' })
                ) : (
                  <InvoiceDiscrepancyTooltipTitle
                    labelIds={invoiceDiscrepancyLabelIds ?? []}
                    invoiceLink={payableFee.invoice_link_for_issuer ?? undefined}
                  />
                )
              }
            >
              <Icon
                sx={{
                  marginLeft: 0.5,
                  color: 'background.paper',
                  path: {
                    color: hasProcessingIssue ? 'error.main' : 'common.orange',
                  },
                }}
              >
                <Attention2Icon />
              </Icon>
            </DiscrepancyTooltip>
          )}
        </Stack>
      </GridCell>

      <GridCell borderBottom noVerticalPadding width={isMultipleItemsView ? 200 : 180}>
        <Stack flexDirection="row" gap={2}>
          {renderFeeStatus()}
        </Stack>
      </GridCell>

      <GridCell borderBottom pr={0}>
        <Price currency={currencySymbol} price={total_payment} isOldPrice={isVoided} />
      </GridCell>
      <GridCell width={20} borderBottom>
        {contextMenu}
      </GridCell>
    </TableRow>
  );
};

type LineItemRowProps = {
  isDeleted?: boolean;
  isLastItemRow: boolean;
  isMultipleItemsView: boolean;
  item: Omit<LineItemCommon, 'id'>;
  student?: DependantStudent;
  currency: string;
  discrepancyRecord?: ReactNode;
};

export const LineItemRow: FC<LineItemRowProps> = ({
  isDeleted,
  isLastItemRow,
  isMultipleItemsView,
  item,
  student,
  currency,
  discrepancyRecord,
}) => {
  const { formatMessage } = useIntl();

  return (
    <TableRow
      sx={(theme) => ({
        height: '40px !important',
        '.MuiTypography-root, .MuiChip-root.MuiChip-outlined': {
          color: isDeleted ? theme.palette.text.secondary : undefined,
          textDecoration: isDeleted ? 'line-through' : undefined,
        },

        '&:hover': {
          '.MuiTypography-root': {
            color: isDeleted ? theme.palette.text.primary : theme.palette.primary.main,
          },
          'td.MuiTableCell-root': {
            backgroundColor: theme.palette.background.default,
          },
          [` .${PRICE_SUBTEXT_CLASS_NAME}`]: {
            color: theme.palette.text.primary,
          },
          ' .MuiChip-root': {
            bgcolor: theme.palette.background.paper,
            color: isDeleted ? theme.palette.text.primary : theme.palette.primary.main,
          },
        },
      })}
    >
      <GridCell
        borderBottom={!isLastItemRow}
        noVerticalPadding
        colSpan={isMultipleItemsView ? 2 : 3}
      >
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          sx={{
            maxWidth: 320,
          }}
        >
          <TypographyWithOverflowHint
            component={Link}
            to={{ pathname: `/settings/products/${item.product_id}` }}
            variant="h3"
            color="text.primary"
            sx={{ '&:hover': { textDecoration: isDeleted ? undefined : 'underline' } }}
          >
            {item.label}
          </TypographyWithOverflowHint>
          {item.product_type === ProductBillingType.OneOff && (
            <Chip
              variant="outlined"
              label={formatMessage({ id: 'products-ProductType-OneOff' })}
              sx={(theme) => ({
                borderRadius: theme.spacing(0.25),
                maxHeight: 22,
                bgcolor: 'background.default',
                '.MuiChip-label': { padding: theme.spacing(0.25, 0.5) },
              })}
            />
          )}
        </Stack>
      </GridCell>
      <GridCell
        borderBottom={!isLastItemRow}
        noVerticalPadding
        sx={{
          position: 'relative',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mr={2}
          sx={{
            width: isMultipleItemsView ? 220 : 150,
          }}
          gap={2}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            {isMultipleItemsView && student && (
              <TypographyWithOverflowHint
                width={90}
                component={Link}
                to={`/reports/${student.id}`}
                color="common.grey2"
                sx={{ '&:hover': { textDecoration: 'underline' } }}
              >
                {getUserFullName(student)}
              </TypographyWithOverflowHint>
            )}
            <TypographyWithOverflowHint color="text.primary">
              <Price
                currency={currency}
                isOldPrice={!!item.discount_percent}
                variant="body1"
                price={item.price_full}
              />
            </TypographyWithOverflowHint>
          </Stack>

          <Typography
            width={50}
            noWrap
            color={item.discount_percent ? 'common.grey2' : 'common.grey'}
            sx={{
              '&.MuiTypography-root.MuiTypography-body1 ': {
                textDecoration: item.discount_percent && isDeleted ? 'line-through' : 'none',
              },
            }}
          >
            {item.discount_percent ? (
              <>
                {item.discount_percent}
                <Typography color="common.grey" display="inline">
                  %
                </Typography>
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Stack>
      </GridCell>

      <GridCell borderBottom={!isLastItemRow} noVerticalPadding pr={0} width={90}>
        <Price color="text.primary" currency={currency} variant="body1" price={item.price} />
      </GridCell>

      <GridCell borderBottom={!isLastItemRow} noVerticalPadding>
        {discrepancyRecord}
      </GridCell>
    </TableRow>
  );
};

export type PayableFeesSkeletonRowsProps = {
  rowsCount: number;
  cellsCount: number;
};

export const PayableFeesSkeletonRows: FC<PayableFeesSkeletonRowsProps> = ({
  rowsCount,
  cellsCount,
}) => {
  const skeleton = (
    <Typography>
      <Skeleton variant="text" />
    </Typography>
  );
  return (
    <>
      {[...new Array(rowsCount)].map((r) => (
        <TableRow key={r}>
          {[...new Array(cellsCount)].map((c) => (
            <GridCell noVerticalPadding key={c}>
              {skeleton}
            </GridCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

type StudentAvatarProps = {
  student: DependantStudent;
};

const StudentAvatar: FC<StudentAvatarProps> = ({ student }) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: 200,
            padding: 1.25,
          },
        },
      }}
      title={
        <Link to={getRouteModalPathname('student', { relation_id: student.id })}>
          <Typography sx={{ '&:hover': { textDecoration: 'underline' } }}>
            {getUserFullName(student)}
          </Typography>
        </Link>
      }
      arrow
    >
      <Box
        sx={(theme) => ({
          border: '1px solid transparent',
          borderRadius: '50%',
          '&:hover': {
            borderColor: theme.palette.primary.main,
          },
        })}
      >
        <AvatarAuth user={student} />
      </Box>
    </Tooltip>
  );
};
