import { Button } from '@mui/material';
import { AttendanceExportForPageRequest, useExportAttendanceForPage } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageExport } from '@schooly/components/export';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import { ContextMenuAction } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { downloadFile } from '../../../utils/downloadFile';

export type AttendanceExportProps = {
  params: AttendanceExportForPageRequest;
  onSuccess?: () => void;
  onError?: () => void;
};

type ExportStatus = 'success' | 'error';

export const AttendanceExport: FC<AttendanceExportProps> = ({ params, onSuccess, onError }) => {
  const { formatMessage } = useIntl();
  const { schoolId } = useAuth();

  const [exportStatus, setExportStatus] = useState<ExportStatus | null>(null);

  const { mutateAsync: exportAttendanceForPage, isLoading } = useExportAttendanceForPage();

  const resetExportStatus = useCallback(() => {
    setTimeout(() => setExportStatus(null), DELAY_BEFORE_HIDE_ICON_DONE);
  }, []);

  const handleError = useCallback(() => {
    onError?.();
    setExportStatus('error');
    resetExportStatus();
  }, [onError, resetExportStatus]);

  const handleSuccess = useCallback(() => {
    onSuccess?.();
    setExportStatus('success');
    resetExportStatus();
  }, [onSuccess, resetExportStatus]);

  const handleExportClick = useCallback(async () => {
    if (!schoolId) return;

    await exportAttendanceForPage(params, {
      onSuccess: (data) => {
        try {
          const timestamp = format(new Date(), 'dd-MM-yy_HH-mm');
          downloadFile(data, `attendance_${timestamp}.csv`);

          handleSuccess();
        } catch (err) {
          handleError();
        }
      },
      onError: handleError,
    });
  }, [schoolId, exportAttendanceForPage, params, handleError, handleSuccess]);

  const renderMenuItem = useCallback(
    ({ labelTextId, onClick }: ContextMenuAction, close: () => void) => {
      return (
        <Button
          variant="outlined"
          onClick={() => {
            onClick();
            close();
          }}
          disabled={!schoolId}
          fullWidth
        >
          {formatMessage({ id: labelTextId })}
        </Button>
      );
    },
    [formatMessage, schoolId],
  );

  return (
    <PageExport
      actions={[{ labelTextId: 'attendance-ExportFilteredAttendance', onClick: handleExportClick }]}
      renderMenuItem={renderMenuItem}
      isLoading={isLoading}
      exportStatus={exportStatus}
    />
  );
};
